import styled from "styled-components";
import {colors} from "../static/themes";

const Text = styled.div`
    font-size: 1.3rem;
    line-height: 1.9rem;
    color: ${props => props.color ? props.color : colors.black_light};
    margin: ${props => props.margin};
    text-align: ${props => props.textAlign};
    max-width: ${props => props.maxWidth ? props.maxWidth : "40em"};
    width: fit-content;
`;

export const TextNote = styled(Text)`
    font-size: 0.8rem;
    line-height: 1.4rem;
    color: ${props => props.color ? props.color : colors.black};
`;

export default Text