import styled from "styled-components";
import {colors, sizes} from "../static/themes";

const Item = styled.h1`
    font-size: 12pt;
    color: ${colors.secondary};
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 2em;
    @media screen and (min-width: ${sizes.desktop}) {
        font-size: 14pt;
    }

    &:after {
        display: flex;
        content: '';
        background-color: ${colors.secondary};
        width: 2em;
        height: .2em;
        border-radius: 8px;
        box-sizing: border-box;
        margin-top: .5em;
        @media screen and (min-width: ${sizes.desktop}) {
            width: 4em;
        }
    }
`

const Pagename = (props) => {
  return (
    <Item>{props.pagename}</Item>
  )
}

export default Pagename;