import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./pages/Layout";
import GlobalStyles from "./components/GlobalStyles";
import {Suspense} from "react";
import loadable from '@loadable/component'
import Reasons from "./pages/offers/reasons";

const PageStart = loadable(() => import('./pages/start'));
const PageImprint = loadable(() => import('./pages/imprint'));
const PagePrivacy = loadable(() => import('./pages/privacy'));
const PagePsychologischeBeratung = loadable(() => import('./pages/offers/psychological-consulting'));
const PageHypnose = loadable(() => import('./pages/offers/hypnose'));
const PageElternCoaching = loadable(() => import('./pages/offers/parent-coaching'));
const PageWorkshops = loadable(() => import('./pages/offers/workshops'));

const Loading = () => {
  setTimeout(() => {
    return "Laden ..."
  }, 500)
}

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <GlobalStyles/>
        <Suspense fallback={<Loading/>}>
          <Routes>
            <Route path={"/"} element={<Layout/>}>
              <Route path={"/"} element={<PageStart/>}/>
              <Route path={"/impressum"} element={<PageImprint/>}/>
              <Route path={"/datenschutz"} element={<PagePrivacy/>}/>
              <Route path={"/psychologische-beratung"} element={<PagePsychologischeBeratung/>}/>
              <Route path={"/hypnose"} element={<PageHypnose/>}/>
              <Route path={"/eltern-coaching"} element={<PageElternCoaching/>}/>
              <Route path={"/workshops"} element={<PageWorkshops/>}/>
              <Route path={"/gruende-fuer-coaching"} element={<Reasons/>}/>
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
