import {createGlobalStyle} from 'styled-components'
import {colors} from "../static/themes";
import backgroundImage from "../assets/Background.svg";

const GlobalStyles = createGlobalStyle`
    body, html {
        font-family: "Helvetica Neue", serif;
        color: ${colors.black};
        letter-spacing: 0.07em;
        background: ${colors.background};
        background-image: url(${backgroundImage});
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: top center;
        padding: 0;
        margin: 0;
        font-size: 10pt;
        box-sizing: border-box;
        width: 100%;
        min-height: 100vh;
        scroll-behavior: smooth;
    }
`

export default GlobalStyles
