import styled from "styled-components";
import {colors, sizes} from "../static/themes";

const Button = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.fullWidth ? "100%" : "fit-content"};
    padding: 1em 2em 1em 2em;
    height: 3.5em;
    font-size: 1rem;
    background-image: linear-gradient(45deg, ${colors.primary}, ${colors.primary});
    color: ${colors.white};
    box-sizing: border-box;
    border-radius: ${sizes.border_radius};
    text-decoration: none;
    border: ${sizes.border_width} transparent solid;

    ${(props) => {
        if (props.disabled === true) {
            return `
                opacity: 0.5;
                pointer-events:none;
                cursor: not-allowed;
            `
        }
    }}
    &:hover {
        cursor: pointer;
        background: ${colors.secondary};
    }
`;

export const BtnLightTrans = styled(Button)`
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(7px);
    color: ${colors.black};
    border: none;
    box-shadow: 0 0 4px 2px ${colors.primary_trans};

    &:hover {
        box-shadow: 0 0 25px 5px ${colors.primary_trans};
        background: rgba(255, 255, 255, 1);
    }
`

export const ButtonPromo = styled(Button)`
    background-image: linear-gradient(45deg, ${colors.primary}, ${colors.secondary});
    border: none;

    &:hover {
        box-shadow: 0 8px 25px 5px ${colors.primary_trans};
        background-image: linear-gradient(45deg, ${colors.primary}, ${colors.secondary});
    }
`

export default Button;