import React from "react";
import Wrapper, {Page, Stage} from "../../components/Wrapper";
import {SectionHeadline} from "../../components/Headline";
import Text from "../../components/Text";
import {BookingLinks, colors, sizes} from "../../static/themes";
import styled from "styled-components";
import imgOffer from "../../assets/bgOffers.svg";
import {ButtonPromo} from "../../components/Button";
import Pagename from "../../components/Pagename";

const CPage = styled(Page)`
    background-image: url(${imgOffer});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    max-width: ${sizes.max_width_medium};
    margin: 0 auto 0 auto;
`
const Reason = styled.div`
    display: flex;
    flex: 1 1;
    flex-basis: 100%;
    font-size: 1.3rem;
    line-height: 1.9rem;
    padding: .5em;
    border-bottom: ${sizes.border_width} ${colors.secondary_trans} solid;
    @media screen and (min-width: ${sizes.tablet}) {
        flex-basis: 30%;
        min-width: 30%;
    }
`

const Workshops = () => {
  document.title = "Alina Pivorius - 10 Gründe für ein Coaching/eine Beratung"

  window.scrollTo(0, 0)

  const reasons = [
    "1. Ordnung – ein Coaching/eine Beratung kann hilfreich sein beim Sortieren von Gedankenchaos, sodass du wieder durchatmen kannst. ",
    "2. Altes loslassen – ein Coaching/eine Beratung schafft Platz für Neues, indem wir alte Verhalten, die dir nicht mehr dienen oder auch blockierende Gedankenmuster aufdecken und auflösen können. Ebenso wie Gefühle, die noch gefühlt werden wollen, endlich gefühlt werden und Platz für neue – schöne Gefühle machen.",
    "3. Lösungen finden – ein Coaching/eine Beratung setzt da an, wo du selbst einfach nicht weiter weißt oder weiterkommst, und unterstützt dich dabei eine passende Lösung für dich zu finden.",
    "4. Zielfindung & Zielerreichung– ein Coaching/eine Beratung verschafft dir Klarheit über deine Wünsche und Träume und begleitet dich dabei diese auch wirklich umzusetzen und zu verwirklichen.",
    "5. Entscheidungen treffen - ein Coaching/ eine Beratung kann dir eine Stütze sein zu lernen effizient und im Einklang mit deinen Bedürfnissen Entscheidungen proaktiv zu treffen. ",
    "6. Feedback/neue Blickwinkel– ein Coaching/ eine Beratung schafft dir die Möglichkeit neue Blickwinkel einzunehmen und verringert so die blinden Flecke, die wir alle haben und die uns unbewusst beeinflussen.",
    "7. Stressbewältigung & Entspannung – ein Coaching/eine Beratung kann mit dem Üben von expliziten Entspannungstechniken und dem Besprechen von Stressmanagement-Themen dazu beitragen im Alltag immer mehr Entspannungsanker und mehr Achtsamkeit einfließen zu lassen. Wenig(er) Stress trägt nachweislich zur Erhaltung bzw. Verbesserung der Gesundheit bei.",
    "8. Stärkeres Selbstvertrauen & Selbstwirksamkeit – ein Coaching/eine Beratung führt durch das Erleben von Erfolg (zum Beispiel dem Erreichen von Zielen) und dem Gefühl, so akzeptiert zu werden, wie man ist, dazu das man ein stärkeres Vertrauen in sich selbst und seine eigenen Fähigkeiten erlangt. ",
    "9. Weiterentwicklung & persönliches Wachstum – ein Coaching/eine Beratung unterstützt dich dabei, dich selbst zu reflektieren, und schafft so das notwenige Fundament für dein persönliches Wachstum und deine mentale Weiterentwicklung.",
    "10. (Selbst-)Liebe – ein Coaching/ eine Beratung hat meiner Meinung nach ganz nach einem meiner Lieblings-Sätze die Grundlage: „Die Antwort ist immer Liebe. – Und diese beginnt immer bei uns selbst.“",
  ]

  return (
    <CPage>
      <Stage>
        <Pagename pagename={"Erfahre warum"}/>
        <SectionHeadline>
          10 Gründe für ein Coaching/eine Beratung
        </SectionHeadline>
        <Text maxWidth={"70em"}>
          Warum ein Coaching oder eine psychologische Beratung dein Leben positiv verändern werden.
        </Text>
      </Stage>

      <Wrapper maxWidth={sizes.max_width_medium}
               gap={"2em"}
               margin={"2em 0 0 0"}>
        {reasons.map(item => {
          return (
            <Reason>
              {item}
            </Reason>
          )
        })}
      </Wrapper>

      <Wrapper maxWidth={sizes.max_width_medium}
               gap={"2em"}
               flexDirection={"column"}
               margin={"2em 0 0 0"}>
        <Text>
          All diese Gründe führen zu mehr Leichtigkeit, mehr Entspannung im Alltag und mehr Selbstbewusstsein. Sie geben
          dir die Möglichkeit, dein Leben genauso zu leben, wie du es dir wünschst, als der Mensch der du gerne sein
          möchtest.<br/>
          Also worauf wartest du noch?!
        </Text>
      </Wrapper>

      <Wrapper maxWidth={sizes.max_width_medium}
               gap={"2em"}
               justifyContent={"center"}
               margin={"2em 0 4em 0"}>
        <a href={BookingLinks.consulting}
           target={"_blank"}
           rel="noreferrer"
           style={{textDecoration: "none"}}
           title={"Schreibe mir"}>
          <ButtonPromo>
            Psychologische Beratung buchen
          </ButtonPromo>
        </a>
        <a href={BookingLinks.parent}
           target={"_blank"}
           rel="noreferrer"
           style={{textDecoration: "none"}}
           title={"Schreibe mir"}>
          <ButtonPromo>
            Eltern-Coaching buchen
          </ButtonPromo>
        </a>
      </Wrapper>
    </CPage>
  )
}

export default Workshops