import React from "react";
import styled from "styled-components";
import {colors, sizes} from "../static/themes";
import {NavLink} from "react-router-dom";
import icoClose from "../assets/ico_close.svg";
import {NavHashLink} from "react-router-hash-link";
import Button from "./Button";
import {ExternalLink} from "./Link";

const Item = styled.div`
    display: flex;
    position: fixed;
    flex-direction: column;
    top: 0;
    left: 0;
    padding: 2em;
    width: 100%;
    box-sizing: border-box;
    background: ${colors.white};
    z-index: 1000;
    box-shadow: ${sizes.box_shadow} ${colors.black_transparent};
`;
const Head = styled.div`
    display: flex;
    justify-content: space-between;
`;
const CloseBtn = styled.img`
    width: 1.7em;
    height: 1.7em;
    margin: .5em 0 0 0;
`;
const Nav = styled.div`
    margin: 2em 0 0 0;
`;
const MobileLink = styled(NavLink)`
    display: block;
    width: 100%;
    text-decoration: none;
    border: none;
    background: transparent;
    color: ${colors.primary};
    margin: 1em 0 2em 0;
    font-size: 1.2rem;
    box-sizing: border-box;
    border-radius: ${sizes.border_radius};
`;

const MobileNav = (props) => {
  const setNavState = () => {
    props.onChange(false)
  }

  return (
    <Item>
      <Head>
        <CloseBtn src={icoClose} onClick={setNavState}/>
      </Head>
      <Nav>
        <MobileLink as={NavHashLink}
                    to={"/#home"}
                    onClick={setNavState}
                    title={"Start"}>
          Start
        </MobileLink>
        <MobileLink as={NavHashLink}
                    to={"/psychologische-beratung"}
                    onClick={setNavState}
                    title={"Psychologische Beratung"}>
          Psychologische Beratung
        </MobileLink>
        <MobileLink as={NavHashLink}
                    to={"/eltern-coaching"}
                    onClick={setNavState}
                    title={"Eltern-Coaching"}>
          Eltern-Coaching
        </MobileLink>
        <MobileLink as={NavHashLink}
                    to={"/hypnose"}
                    onClick={setNavState}
                    title={"Hypnose"}>
          Hypnose
        </MobileLink>
        <MobileLink as={NavHashLink}
                    to={"/workshops"}
                    onClick={setNavState}
                    title={"Workshops"}>
          Workshops
        </MobileLink>
        <MobileLink as={NavHashLink}
                    to={"/gruende-fuer-coaching"}
                    onClick={setNavState}
                    title={"Gründe für Coaching"}>
          Warum Coaching/Beratung?
        </MobileLink>
        <MobileLink as={NavHashLink}
                    to={"/#praxis"}
                    onClick={setNavState}
                    title={"Meine Praxis"}>
          Meine Praxis
        </MobileLink>
        <MobileLink as={NavHashLink}
                    to={"/#uebermich"}
                    onClick={setNavState}
                    title={"Über mich"}>
          Über mich
        </MobileLink>
        <ExternalLink href={"https://alinapivorius.as.me/schedule.php"}
                      title={"Jetzt Termin buchen"}
                      target={"_blank"}>
          <Button>
            Jetzt Termin buchen
          </Button>
        </ExternalLink>
      </Nav>
    </Item>
  )
}

export default MobileNav