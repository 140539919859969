import styled from "styled-components";
import {colors, sizes} from "../static/themes";

const BaseHeadline = styled.h1`
    width: fit-content;
    color: ${props => props.color ? props.color : colors.black};
    text-align: ${props => props.textAlign};
    ${(props) => {
        if (props.disabled === true) {
            return `
                    opacity: 0.2;
                `
        }
    }}
`

export const H1 = styled(BaseHeadline)`
    font-size: 32pt;
    line-height: 37pt;
`;
export const H2 = styled(BaseHeadline)`
    font-size: 30pt;
    line-height: 35pt;
`;
export const H3 = styled(BaseHeadline)`
    font-size: 24pt;
    line-height: 30pt;
`;
export const SectionHeadline = styled(H3)`
    font-weight: 400;
    font-size: 18pt;
    line-height: 24pt;
    margin: 0 0 .5em 0;
    width: 100%;
    text-align: ${props => props.textAlign};
    @media screen and (min-width: ${sizes.tablet}) {
        font-size: 30pt;
        line-height: 34pt;
    }
`;

export const WorkName = styled(H2)`
    font-weight: 300;
    font-size: 20pt;
    line-height: 28pt;
`