import styled from "styled-components";
import {sizes} from "../static/themes";

export const Page = styled.div`
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 1.5em 0 1.5em;
    margin: 0 auto 0 auto;
    width: 100%;
    @media screen and (min-width: ${sizes.tablet}) {
        max-width: 100%;
    }
`

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: ${props => props.flex};
    flex-direction: ${props => props.flexDirection ? props.flexDirection : "row"};
    max-width: ${props => props.maxWidth ? props.maxWidth : '100%'};
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'space-between'};
    align-items: ${props => props.alignItems ? props.alignItems : 'flex-start'};
    margin: ${props => props.margin};
    width: 100%;
    box-sizing: border-box;
    gap: ${props => props.gap};
    padding: ${props => props.padding};
    bottom: ${props => props.bottom};
    background: ${props => props.background};
`;

export const RowReverse = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    max-width: ${sizes.max_width_small};
    align-items: start;
    justify-content: space-between;

    @media screen and (min-width: ${sizes.desktop}) {
        flex-direction: row-reverse;
    }
`

export const Stage = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 2em 0 2em 0;
    @media screen and (min-width: ${sizes.desktop}) {
        margin: 4em 0 4em 0;
    }
`

export default Wrapper