export const colors = {
  primary: 'rgb(175,107,238)',
  primary_trans: 'rgba(175,107,238, 0.2)',
  secondary: '#bb1988',
  secondary_trans: 'rgba(187,25,136,0.2)',
  background: 'rgb(246,245,248)',
  black: '#161C2E',
  black_transparent: 'rgba(22,28,46,0.14)',
  grey: '#303434',
  grey_light: '#dfdfdf',
  grey_trans: 'rgba(222,222,222,0.37)',
  white: '#FFFFFF',
}

export const sizes = {
  padding: '1.5em',
  border_radius: '15px',
  border_width: '1.5px',
  border_width_medium: '2.5px',
  max_width: '1400px',
  max_width_medium: '1300px',
  max_width_small: '1100px',
  max_width_supersmall: '900px',
  box_shadow: '0 2px 5px',
  box_shadow_medium: '0 4px 10px',
  box_shadow_big: '0 10px 25px',
  desktop: '1300px',
  notebook: '1000px',
  tablet_big: '768px',
  tablet: '600px',
  mobile: '420px',
  gap: '1.5em',
}

export const BookingLinks = {
  general: "https://alinapivorius.as.me/schedule.php",
  consulting: "https://alinapivorius.as.me/?appointmentType=category:Psychologische%20Beratung",
  parent: "https://alinapivorius.as.me/?appointmentType=category:Eltern-Coaching",
  hypnose: "https://alinapivorius.as.me/?appointmentType=category:Hypnose",
  workshops: "",
}

export const devices = {}