import styled from "styled-components";
import {NavHashLink} from "react-router-hash-link";
import {colors} from "../static/themes";
import {NavLink} from "react-router-dom";

const Link = styled(NavLink)`
    display: ${props => props.display};
    color: ${props => props.color ? props.color : colors.primary};
    font-size: 12pt;
    width: fit-content;
    text-decoration: underline;

    &:hover {
        color: ${colors.primary_dark};
    }
`;

export const LinkBlack = styled(Link)`
    color: ${colors.black};
    text-decoration: none;
`

export const PageLink = styled(NavHashLink)`
    text-decoration: none;
    display: ${props => props.display};
    color: ${props => props.color ? props.color : colors.primary};
    font-size: 1.4rem;
    width: fit-content;

    &:hover {
        color: ${colors.primary_dark};
    }
`;

export const ExternalLink = styled.a`
    text-decoration: none;
`;

export default Link