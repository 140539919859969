import styled from "styled-components";
import {colors, sizes} from "../static/themes";
import {BtnLightTrans} from "./Button";
import Wrapper from "./Wrapper";
import {NavHashLink} from "react-router-hash-link";
import logoIcon from "../assets/ico.png";
import icoHome from "../assets/house.svg";
import icoHamburger from "../assets/hamburger.svg";
import {useEffect, useState} from "react";
import MobileNav from "./MobileNav";

const Container = styled.div`
    display: flex;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 5em;
    padding: 1em 2em 1em 1em;
    margin: 0 auto 0 auto;
    max-width: ${sizes.max_width};
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    border-radius: ${sizes.border_radius};
    transition: background .2s ease;
    z-index: 1000;
    backdrop-filter: blur(7px);
    @media screen and (min-width: ${sizes.desktop}) {
        top: .8em;
    }
`
const Navigation = styled(Wrapper)`
    display: none;
    @media screen and (min-width: ${sizes.notebook}) {
        display: flex;
    }
`
const Hamburger = styled.img`
    display: flex;
    width: 2em;
    height: 2em;
    opacity: 0.8;
    @media screen and (min-width: ${sizes.notebook}) {
        display: none;
    }
`
const LogoName = styled.div`
    display: flex;
    font-size: 2rem;
    font-weight: 300;
    margin: 0 0 0 0.5em;
`
const NavLink = styled(NavHashLink)`
    display: flex;
    font-size: 1rem;
    color: ${colors.black};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`
const Icon = styled.img`
    display: flex;
    height: 4.5em;
    width: 4.5em;
    border-radius: 100%;
    padding: .5em;
    box-sizing: border-box;
    @media screen and (min-width: ${sizes.tablet}) {
        height: 5em;
        width: 5em;
    }
`
const IcoHome = styled.img`
    display: flex;
    height: 1.2em;
    width: 1.2em;
`
const HeaderLInk = styled(NavLink)`
    display: flex;
    width: 100%;
    max-width: fit-content;
    align-items: center;
`

const Header = () => {
  useEffect(() => {
    var header = document.getElementById('header');
    window.onscroll = function () {
      var scroll = window.scrollY;
      if (scroll > 100) {
        header.style.backgroundColor = "rgba(255,255,255,0.9)";
      } else if (scroll < 100) {
        header.style.backgroundColor = 'transparent';
      }
    }
  }, [])

  const [navOpen, setNavOpen] = useState(false)
  const setNavState = (newValue) => {
    setNavOpen(newValue);
  }

  return (
    <Container id={"header"}>
      {navOpen ? <MobileNav
        navOpen={navOpen}
        onChange={setNavState}
      /> : null}

      <HeaderLInk to={"/"}>
        <Icon src={logoIcon} alt={"Logo Alina Pivorius Mental Coaching"}/>
        <LogoName>Alina Pivorius</LogoName>
      </HeaderLInk>
      <Navigation alignItems={"center"}
                  gap={"3em"}
                  justifyContent={"end"}>
        <Wrapper maxWidth={"fit-content"} gap={"2em"} alignItems={"center"}>
          <NavLink to={"/#home"} title={"Start"}>
            <IcoHome src={icoHome}/>
          </NavLink>
          <NavLink to={"/psychologische-beratung"} title={"Psychologische Beratung"}>
            Psychologische Beratung
          </NavLink>
          <NavLink to={"/eltern-coaching"} title={"Eltern-Coaching"}>
            Eltern-Coaching
          </NavLink>
          <NavLink to={"/hypnose"} title={"Hypnose"}>
            Hypnose
          </NavLink>
          <NavLink to={"/workshops"} title={"Workshops"}>
            Workshops
          </NavLink>
          <NavLink to={"/#praxis"} title={"Meine Praxis"}>
            Meine Praxis
          </NavLink>
          <NavLink to={"/#uebermich"} title={"Über mich"}>
            Über mich
          </NavLink>
        </Wrapper>

        <a href={"https://alinapivorius.as.me/schedule.php"}
           target={"_blank"}
           rel="noreferrer"
           style={{textDecoration: "none"}}
           title={"Termin buchen"}>
          <BtnLightTrans>Termin buchen</BtnLightTrans>
        </a>
      </Navigation>

      <Hamburger
        src={icoHamburger}
        alt={"Navigtion Icon"}
        title={"Navigation öfnen"}
        onClick={() => setNavOpen(true)}
      />
    </Container>
  )
}

export default Header