import React from "react";
import styled from "styled-components";
import {colors, sizes} from "../static/themes";
import {NavLink} from "react-router-dom";
import Wrapper from "./Wrapper";

const Item = styled.div`
    display: flex;
    flex-direction: column-reverse;
    gap: 2em;
    max-width: ${sizes.max_width};
    margin: 0 auto 0 auto;
    padding: 2em 1em 2em 1em;
    justify-content: space-between;

    @media screen and (min-width: ${sizes.tablet}) {
        flex-direction: row;
        padding: 2em 0 2em 0;
    }
`
const FooterLink = styled(NavLink)`
    display: flex;
    font-size: 1rem;
    color: ${colors.primary};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`

const Footer = () => {
  return (
    <Item>
      &copy; {(new Date()).getFullYear()} Alina Pivorius Coaching - Alle Rechte vorbehalten
      <Wrapper maxWidth={"fit-content"} gap={"2em"}>
        <FooterLink to={{pathname: "/impressum"}} title={"Impressum"}>
          Impressum
        </FooterLink>
        <FooterLink to={{pathname: "/datenschutz"}} title={"Datenschutz"}>
          Datenschutz
        </FooterLink>
      </Wrapper>
    </Item>
  )
}

export default Footer